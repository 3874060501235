import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import { HeaderPlaceholder, Underline, Button } from "../../styles/ui.styles";
import { SwiperWrapper } from "../../styles/swiper.styles";
import SubscribeForm from "../../components/subscribeForm";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { Navigation, Thumbs } from "swiper";
SwiperCore.use([Navigation, Thumbs]);
import IconIS from "../../images/svg/increase_speed.inline.svg";
import IconLW from "../../images/svg/lightweight.inline.svg";
import IconACC from "../../images/svg/accuracy.inline.svg";
import IconDS from "../../images/svg/data_security.inline.svg";
import IconCS from "../../images/svg/cloud_system.inline.svg";
import IconWD from "../../images/svg/waterproof_design.inline.svg";
import { FaCheck } from "react-icons/fa";
import Configurator from "../../components/configurator/configurator";
import useScrollTo from "../../lib/scrollto";
import ExpandableItem from "../../components/expandableItem";

const DinghyEdition: React.FC = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const { scrollTo } = useScrollTo();
  return (
    <>
      <SEO title={"Dinghy Edition"} description={"Dinghy Edition"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <SliderSection>
            <div>
              <h1>Dinghy Edition</h1>
              <p>
                Fastrrr offers a refined and efficient training experience
                through gathering training data and providing insights based on
                the AI enhanced analytics solution, which will help professional
                sailors achieve better results and maximize their boats
                performance.
              </p>
              <Buttons>
                <Button
                  onClick={() => scrollTo("#configurator", -100)}
                  primary={true}
                >
                  CONFIGURE
                </Button>
              </Buttons>
            </div>
            <SwiperWrapper>
              <Swiper
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                className="mainSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition1.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition2.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition3.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                onSwiper={(swpr: SwiperCore) => setThumbsSwiper(swpr)}
                loop={false}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={false}
                watchSlidesProgress={true}
                className="thumbnailSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition1.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition2.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/dinghy_edition3.jpg"}
                    alt={"Dinghy Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </SliderSection>

          <Title id="why" style={{ marginTop: "128px" }}>
            <h2>Why Dinghy Edition?</h2>
            <Underline />
          </Title>
          <p style={{ textAlign: "center", marginBottom: "64px" }}>
            To boost performance the use of sophisticated tools that provide
            instant feedback and insights on a sailor’s performance are becoming
            more and more essential. Fastrrr allows these debriefing sessions to
            be more effective, by having an instantly accessible view of the
            training session.
          </p>
          <Grid>
            <div>
              <IconIS />
              <h3>Increase Speed</h3>
              <p>
                Collect data and we deliver sailing insights to help you
                maximize performance.
              </p>
            </div>

            <div>
              <IconLW />
              <h3>Light weight</h3>
              <p>
                On high performance racing boats every gram counts. We designed
                our product to be as light as possible.
              </p>
            </div>

            <div>
              <IconACC />
              <h3>Accuracy</h3>
              <p>
                Fastrrr’s cutting edge industrial grade GPS and sensor fusion
                technology gives outstanding accuracy.
              </p>
            </div>

            <div>
              <IconDS />
              <h3>Data security</h3>
              <p>
                No one has access to your own sailing data. We store data in
                encrypted form.
              </p>
            </div>
            <div>
              <IconCS />
              <h3>Easy to install</h3>
              <p>
                Quad Lock® is the strongest and most secure mounting system in
                the world that holds your device steady in rough conditions.
              </p>
            </div>
            <div>
              <IconWD />
              <h3>Waterproof design</h3>
              <p>Tested in extreme conditions to ensure reliable operation.</p>
            </div>
          </Grid>

          <Title>
            <h2>Topology</h2>
            <Underline />
          </Title>

          <div style={{ width: "100%" }}>
            <StaticImage
              style={{ width: "100%" }}
              src={"../../images/dinghy_graph.png"}
              alt={"DinghyEdition Graph"}
              objectFit={"contain"}
            />
          </div>

          <Title>
            <h2>What’s more?</h2>
            <Underline />
          </Title>

          <WhatsMoreGrid>
            <div>
              <p>
                <strong>
                  With the help of the Dinghy Edition data gathering hardware
                  and DataBay analytics interface,
                </strong>
                <br />
                <br />
                your logs and boat settings can be stored in one place,
                complemented by your sailing performance data to ensure your
                progress. You can also sync your photos or videos recorded
                during sailing and measured data, especially from the coach
                boat, this way sailors could check each sail and rig settings
                after trainings and competitions to analyze it with recorded
                performance data.
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Cloud system
                  </StyledListElement>
                  <p>
                    You can sync your photos or videos recorded during sailing
                    and check what happened on the boat during any event.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Long battery
                  </StyledListElement>
                  <p>Allows longer and more flexible training sessions.</p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Real time analysis
                  </StyledListElement>
                  <p>
                    By using Fastrrr, you not only get the momentarily data, but
                    with real time analysis you get the optimal targets for the
                    current conditions and the settings needed for maximal
                    performance.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Easy data access
                  </StyledListElement>
                  <p>Available sailing data from any device.</p>
                </li>
              </ul>
            </div>
            <div>
              <p>
                <strong>
                  The Dinghy Edition onboard unit is perfectly suitable for
                  smaller boats, which doesn’t have previously installed sailing
                  instruments.
                </strong>
                <br />
                <br />
                It allows sailing sessions to be more effective, by having an
                instantly accessible view of the boat motion data, weather
                conditions and data analytics-based insights to maximize boat
                performance.
                <br />
                The device <b>connects wirelessly</b> through Bluetooth, WiFi to
                any smart devices...
              </p>
            </div>
          </WhatsMoreGrid>
          <Title>
            <h2>More details</h2>
            <Underline />
          </Title>
          <ExpandableGrid>
            <ExpandableItem
              title={"What’s in the box?"}
              expandableText={
                "– Dinghy Edition instrument\n– Inductive charging pad\n– USB mini-B charging cable\n– USB wall charger\n– Mounting bracket\n\nPlease notice our product manual is only available online. Please download the latest version of manual from the support page."
              }
            />
            <ExpandableItem
              title={"Features"}
              expandableText={
                "– 10 Hz refresh rate GPS\n– 100Hz refresh rate IMU sensor\n– Over 8 hours of battery life\n– Data download over-the-air\n– Automatic magnetic declination\n– Waterproof (IPX8) to 3m\n– Two-year warranty"
              }
            />
            <ExpandableItem
              title={"Technical specifications"}
              expandableText={
                "– GPS networks: multi-GNSS (GPS/QZSS, GLONASS and BeiDou)\n– GPS Frequency: 10 Hz\n– Inertial Measurement Unit: 9 axis 1kHz\n– Compass: 3 axis magnetometer, compensated with 3 gyroscopes\n– Size: 140x80x75 mm\n– Weight: 450gr\n– Supply voltage: 5V DC\n– Battery rated capacity: 3900 mAh / 14.82 Wh\n– Runtime on single charge: 8-14 hrs"
              }
            />
            <ExpandableItem
              title={"Shipping details"}
              expandableText={
                "Your order will be processed and shipped from our office in the Hungary (Veszprem). Depending on where your order is shipped to, the following approximate delivery times will apply: \n\n – Europe: 3-5 business days* \n – Outside Europe: 5-7 business days* \n – Worldwide shipping will be handled by UPS (Track and Trace included) \n\n * No rights can be derived from these approximate delivery times"
              }
            />
          </ExpandableGrid>
        </Wrapper>
        <div id="configurator" />
        <Configurator forBoatType={"dinghy"} />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default DinghyEdition;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;

  p {
    color: var(--color-dark-grey);
  }
`;

const SliderSection = styled.section`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 64px;

  div:first-child {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Title = styled.div`
  margin: 70px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    margin-top: 32px;
  }
  div {
    margin: 32px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  h3,
  p {
    padding: 0 10px;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const WhatsMoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
  }

  div:nth-child(2),
  div:nth-child(3) {
    background: #f3f3f3;

    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    div:nth-child(3) {
      order: 4;
    }
    div:nth-child(4) {
      order: 3;
    }
  }
`;

const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
`;

const ExpandableGrid = styled.div`
  p {
    color: unset;
  }
  display: grid;
  white-space: pre-line;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
